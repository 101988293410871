import React, {useState, useEffect} from 'react'
import mockup from "../../assets/dazzle-money-rise.gif"
import logo from "../../assets/BloomFi_logo-full_draft4 1.svg"
import Modal from '../Modal/Modal'

import "./header.scss"
import { HashLink as Link } from 'react-router-hash-link'


function Mailto({ email, subject, body, ...props }) {
    return (
      <a href={`mailto:${email}?subject=${subject || ""}&body=${body || ""}`}>
        {props.children}
      </a>
    );
}

const Header = () => {
    const[showModal, setShowModal] = useState(false)
    

  return (
    <div className='header'>
        <div className='header__logo'>
            <img src={logo} alt="logo" />
            <div className='header__logo-btn'>
                <Link to="#faq"> <button className='btt'>FAQs</button> </Link>  
                <Mailto email="info@bloomfi.tech" subject="Hello" body="Hello!">
                     <button className="btn">Contact us</button>
                </Mailto>
            </div>
        </div>

        <div className='check'>
        {showModal &&  <div><div onClick={()=>setShowModal(false)} className="modal-main"></div><Modal /></div>}
        <div className='header__info'>
           <h4>
           BloomFi is here to help you settle into your new country with ease
           </h4>

           <p className='info'>
           Settle into your new country (jurisdiction) of residence with ease and  bloom in success
            </p>

          
            <button onClick={()=>{setShowModal(true)}}>
               Join the waitlist
            </button>
    
        </div> 


        <div className='header__mockup'>
            <img src={mockup} alt=""/>
            
        </div>
        </div>
    </div>
  )
}

export default Header;