import React, {useState} from 'react'
import {db} from "../../firebase"
import logo from "../../assets/BloomFi_logo-full_draft4 1.svg"
import {BeatLoader} from "react-spinners"
import "./modal.scss"

const Modal = (props) => {
    const[name, setName] = useState('')
    const[number, setNumber]= useState('')
    const[residence, setResidence]= useState('')
    const[countryIm, setCountryIm]= useState('')
    const[email, setEmail]= useState('')
    const [loading, setLoading] = useState(false)
    const[showMessage, setShowMessage] = useState(false)

    const handleSubmit = (e) =>{
        e.preventDefault();
        setLoading(true)
      
        db.collection("contacts")
       .add({
          email,
          name,
          origin,
          residence,
          countryIm,
       }).then(()=>{
           setShowMessage(true)
           console.log('success')
           setTimeout(() => {
             setShowMessage(false)
             }, 5000); 
             setEmail("")
             setName('')
             setResidence('')
             setNumber('')
             setCountryIm('')
             setLoading(false)
       }).catch((error)=>{
          console.log(error.message)
       })
    }

  return (
    <div className={`modal ${props.className}`}>
        <form onSubmit={handleSubmit}>
        <img src={logo} alt=""/>
        <p>Be one of the first to access BloomFi</p>
        {showMessage && <p className="green">Information sent succesfully</p>}
          <div className='inp'>
            <label>Full Name*</label> 
              <input
                    value={name}
                    placeholder="Full Name"
                    onChange={(e)=> setName(e.target.value)}
                    required
                />
          </div>
          <div className='inp'>
            <label>Phone number</label> 
              <input
                    value={number}
                    placeholder="Enter phone number"
                    onChange={(e)=> setNumber(e.target.value)}
                    required
                />
          </div>
          <div className='inp'>
            <label>Country of residence*</label> 
              <input
                    value={residence}
                    placeholder="Country of Origin"
                    onChange={(e)=> setResidence(e.target.value)}
                    required
                />
          </div>
          <div className='inp'>
            <label>Country you are immigrating to*</label> 
              <input
                    value={countryIm}
                    placeholder="Enter country immigrating to"
                    onChange={(e)=> setCountryIm(e.target.value)}
                    required
                />
          </div>
          <div className='inp'>
            <label>Email*</label> 
              <input
                    value={email}
                    placeholder="Enter your email"
                    onChange={(e)=> setEmail(e.target.value)}
                    type="email"
                    required
                />
          </div>
            <button type="submit">{loading ? <BeatLoader color="#36d7b7" /> : <p>Join the waitlist </p>}</button>
        </form>
        </div>
  )
}

export default Modal;