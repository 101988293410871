import React from "react";
import "./App.scss"
import Header from "./components/Header/Header";
import Info from "./components/Info/Accodion"
import Modal from "./components/Modal/Modal";
import logo from "./assets/BloomFi_logo-full_draft4 1.svg"
import { BrowserRouter } from "react-router-dom";

const App = ()=>{
  return(
    <BrowserRouter>
    <div>
      <Header />
     <Info />

     <div className="footer">
       <img src={logo} alt="logo" />
       <p className="twoo">All Rights Reserved © 2022</p>
     </div>
    </div>
    </BrowserRouter>
  )
}
export default App;
