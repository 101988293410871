import React, {useState, useEffect} from 'react';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import { HashLink as Link } from 'react-router-hash-link'
import chevron from "../../assets/chevron-up (2).svg"

import "./accordion.scss"

// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import Modal from '../Modal/Modal';


export default function Info() {
    const[showModal, setShowModal] = useState(false)

    return (
        <div className='accordion' id="faq">

            <div className='accordion__header'>
                <h4>Frequently Asked Questions</h4>
            </div>
        <Accordion className='ach'  allowZeroExpanded>
            <AccordionItem className="one">
                <AccordionItemHeading>
                    <AccordionItemButton className="btn">
                       <h4> How does BloomFi work?</h4>
                       <img src={chevron} alt=""/>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Our core offering is to obtain, verify, classify and present your financial and other information (history
and credit) from your country of origin to North America (Canada and the United States of America).
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className="one">
                <AccordionItemHeading>
                    <AccordionItemButton className="btn">
                       <h4>What are your product offerings?</h4>
                       <img src={chevron} alt=""/>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <p>
                    Our two products currently being developed are:
                    </p>
                    <p>
                    a. My Report: You give us permission/consent to access your financial and credit history from your
                    country of origin and we analyse and provide it in an acceptable format (a financial report
                    and/or credit score) to our partners (financial institutions, credit reporting agencies and/or real
                    estate companies/landlords) to offer you better financing terms and housing immediately on
                    arrival to Canada and the United States.
                    </p>

                    <p>
                    b. Rent Guarantee: You sign up for our rent guarantee scheme, we seek your consent to obtain
                    your financial and credit history from your country of origin and use this to determine the
                    lowest amount possible you will pay as a premium for us to guarantee your monthly rent to your
                    landlord. This provides your property owner (or landlord) with security, and they can rent a
                    house or apartment to you with no risk at all.
                </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className="one">
                <AccordionItemHeading>
                    <AccordionItemButton className="btn">
                    <h4> Which country is BloomFi available in? </h4>
                       <img src={chevron} alt=""/>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    We are launching in Canada; we will be available in the United States within a couple of months.
                    </p>
                </AccordionItemPanel>

            </AccordionItem>
            <AccordionItem className="one">
                <AccordionItemHeading>
                    <AccordionItemButton className="btn">
                    <h4> From which country of origin can I use BloomFi offerings before coming to North America or
Europe?</h4>
                       <img src={chevron} alt=""/>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    We are launching with processing records from African countries and India. We will be processing
records from the rest of the world within the next 3 months. Please join our waitlist so we can inform
you as we add more countries.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className="one">
                <AccordionItemHeading>
                    <AccordionItemButton className="btn">
                    <h4>How do I sign up for BloomFi?</h4>
                       <img src={chevron} alt=""/>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Please sign up for our waitlist at this link here or above. We will send you a message once our service is
up and running.     <button href="" onClick={()=>{setShowModal(true)}}>
                     Join the waitlist
                    </button>
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className="one">
                <AccordionItemHeading>
                    <AccordionItemButton className="btn">
                    <h4>Is BloomFi a registered corporation?</h4>
                       <img src={chevron} alt=""/>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <p>
                    Yes, we are registered as a corporation in Canada as Bloom Financial Technologies Inc.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem className="one">
                <AccordionItemHeading>
                    <AccordionItemButton className="btn">
                       <h4> Can I settle in other countries with BloomFi? </h4>
                       <img src={chevron} alt=""/>
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                <p>Yes. We are launching in North America specifically Canada and the United States. We are working on
bringing our offerings to the United Kingdom, and the rest of Europe before the end of the year.
                    </p>
                </AccordionItemPanel>
            </AccordionItem>
        </Accordion>

        {showModal &&  <div><div onClick={()=>setShowModal(false)} className="modal-main"></div><Modal className="below" /></div>}
        </div>
    );
}